import { makeStyles, Theme } from "@material-ui/core";

export const basePath =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : "/";

export const baseAPI =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8014/api"
        : "/api";

export const BACKEND =
    process.env.NODE_ENV === "development" ? "http://localhost:8014" : "";

export let appVersion = 1.5;
export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

export const EditLectureInNewTab = (id: string | number) => {
    const url = `${basePath}/employee/edit-lecture/${id}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

export const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        position: "relative",
    },

    cell: {
        // color: theme.palette.secondary.contrastText,
    },

    addSection: {
        // marginTop: "2vh",
        marginBottom: "2vh",
    },

    tableRow: {
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
    },

    tableHead: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
    },

    form: {
        width: "100%",
        "& > *": {
            minWidth: "20%",
            margin: theme.spacing(1),
        },
    },

    root: {
        margin: theme.spacing(1),
    },

    errorBtnContained: {
        background: theme.palette.error.dark,
    },
}));

export function shortener(text: string, maxLength: number) {
    return text.length > maxLength
        ? text.slice(0, maxLength - 2) + "..."
        : text;
}

export const sessionTimeInSeconds = 30 * 60;

export const awsSettings = {
    folder: "account",
    lectureDir: "lecture-data",
    materialDir: "material-files",
    assignmentDir: "assignments-files",
    assignmentSubmissionDir: "assignment-submission-files",
    questionFilesDir: "question-files",
    chatFiles: "chat-files",
    logosDir: "acccount-logos",
    profilePicsDir: "account-profile/pics",
    paymentDir: "account/payment/receipts",
};

export const parseDate = (str: string, includeYear = false) => {
    try {
        const seconds = Date.parse(str);
        const date = new Date(seconds);
        const formatted = new Intl.DateTimeFormat("en", {
            month: "short",
            day: "2-digit",
            year: includeYear ? "numeric" : undefined,
        }).format(date);
        return formatted;
    } catch (err) {
        return str;
    }
};

// export const currencyFormatter = (value: number) => {
//     const formatter = Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "PKR",
//     });
//     return formatter.format(value);
// };

export const currencyFormatter = (value: number) => {
    const formatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PKR",
    });

    // Check if the value is 0 and return a specific string or handle it accordingly
    if (value === 0) {
        return formatter.format(0); // or return a specific string like "PKR 0" if desired
    }

    return formatter.format(value);
};

export const notConnectedMessage =
    "Unable to perform the task at this moment, make sure you're connected to the inernet";

export enum APIRequestStatus {
    idle = "idle",
    loading = "loading",
    error = "error",
    success = "success",
}

export const awsDirStruct = {
    logsInfo: {
        logs: "logs",
    },
    org: {
        picture: "picture/certificate",
    },
};

export const dateFormatter = Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    hour12: true,
    minute: "2-digit",
});

export function lastEntryInMap(map: Map<number, any>) {
    const mapKeys = Array.from(map.keys()).sort((a, b) => a - b);

    return mapKeys[map.size - 1] ?? 0;
}

export function lastIndexInObj(object: { [key: number]: any }) {
    const keys = Object.keys(object)
        .map((x) => +x)
        .sort((a, b) => a - b);

    return keys[keys.length - 1] ?? 0;
}

export async function urlToBlob(url: string) {
    try {
        // Fetch the image
        const response = await fetch(url);

        // Check if the request was successful (status code 200)
        if (!response.ok) {
            throw new Error(
                `Failed to fetch image: ${response.status} ${response.statusText}`
            );
        }

        // Get the image data as an ArrayBuffer
        const buffer = await response.arrayBuffer();

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([buffer], {
            type: response.headers.get("content-type") as any,
        });

        return blob;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const transparency = {
    100: "FF",
    99: "FC",
    98: "FA",
    97: "F7",
    96: "F5",
    95: "F2",
    94: "F0",
    93: "ED",
    92: "EB",
    91: "E8",
    90: "E6",
    89: "E3",
    88: "E0",
    87: "DE",
    86: "DB",
    85: "D9",
    84: "D6",
    83: "D4",
    82: "D1",
    81: "CF",
    80: "CC",
    79: "C9",
    78: "C7",
    77: "C4",
    76: "C2",
    75: "BF",
    74: "BD",
    73: "BA",
    72: "B8",
    71: "B5",
    70: "B3",
    69: "B0",
    68: "AD",
    67: "AB",
    66: "A8",
    65: "A6",
    64: "A3",
    63: "A1",
    62: "9E",
    61: "9C",
    60: "99",
    59: "96",
    58: "94",
    57: "91",
    56: "8F",
    55: "8C",
    54: "8A",
    53: "87",
    52: "85",
    51: "82",
    50: "80",
    49: "7D",
    48: "7A",
    47: "78",
    46: "75",
    45: "73",
    44: "70",
    43: "6E",
    42: "6B",
    41: "69",
    40: "66",
    39: "63",
    38: "61",
    37: "5E",
    36: "5C",
    35: "59",
    34: "57",
    33: "54",
    32: "52",
    31: "4F",
    30: "4D",
    29: "4A",
    28: "47",
    27: "45",
    26: "42",
    25: "40",
    24: "3D",
    23: "3B",
    22: "38",
    21: "36",
    20: "33",
    19: "30",
    18: "2E",
    17: "2B",
    16: "29",
    15: "26",
    14: "24",
    13: "21",
    12: "1F",
    11: "1C",
    10: "1A",
    9: "17",
    8: "14",
    7: "12",
    6: "0F",
    5: "0D",
    4: "0A",
    3: "08",
    2: "05",
    1: "03",
    0: "00",
};
