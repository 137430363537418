import {
    Box,
    Button,
    Checkbox,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PaymentService } from "../../services/PaymentService";
import { currencyFormatter } from "../constants";
import PrintTable from "../export/PrintTable";
import { ReportHeader } from "../settings/Header/ReportHeader";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    feedback: {
        margin: theme.spacing(2),
    },
    noPadding: {
        padding: 0,
    },
    hoverRow: {
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.3)", // grey.300 equivalent
        },
    },

    headerStyling: {
        textTransform: "capitalize",
        "& > *": {
            backgroundColor: "rgba(0,0,0,0.5)",
            fontWeight: "bold",
        },
    },

    stickyStyling: {
        position: "sticky",
        top: 0,
        zIndex: 1000, // Increase zIndex to ensure the header stays on top
        backgroundColor: "rgba(0,0,0,0.8)", // Make it more opaque for better visibility
        fontWeight: "bold",
        "& th": {
            background: "rgba(0,0,0,0.8)",
            zIndex: 1001, // Ensure table headers are above everything else
        },
    },
}));

const BankSummary = () => {
    const classes = useStyles();
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });
    const [totalBalance, setTotalBalance] = useState<any>();
    const location = useLocation();

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        SrNo: true,
        Bank: true,
        Balance: true,
        Detail: true,
    });
    const printRef = useRef<HTMLDivElement | null>(null);

    const [printMode, setPrintMode] = useState(false);
    const [paymentToBePaid, setPaymentToBePaid] = useState<any>();
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const { settings } = useContext(UserContext);
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const [trialBalance, setTrialBalance] = useState<any>();
    const ref = useRef(0);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "bankSummaryRecord",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const getTrialBalance = async () => {
        const [data, err] = await PaymentService.getBanksSummary(
            pagination.page + 1,
            pagination.limit,
            {
                bank: filter.bank ? filter.bank : null,
                startDate: filter.startDate ? filter.startDate : null,
                endDate: filter.endDate ? filter.endDate : null,
                search: filter.search ? filter.search : null,
            }
        );

        if (data?.rows?.length) {
            setTrialBalance(data?.rows);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setPaymentToBePaid([]);
            setTrialBalance([]);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTrialBalance();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("bankSummaryRecord");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                SrNo: true,
                Bank: true,
                Balance: true,
                Detail: true,
            });
        }
    }, []);

    useEffect(() => {
        // Calculate the sum of all payments
        const sum =
            trialBalance?.reduce((acc: any, item: any) => {
                const payment = parseFloat(item.amount) || 0;
                return acc + payment;
            }, 0) || 0;

        setTotalBalance(sum);
    }, [trialBalance]);

    return (
        <Grid container spacing={1} style={{ padding: "2rem" }}>
            <Grid
                container
                style={{ padding: "2rem", justifyContent: "space-between" }}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size="small"
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Hidden smDown>
                            <PrintTable
                                componentRef={printRef}
                                setPrintMode={setPrintMode}
                            />
                        </Hidden>

                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                        />
                    </Box>
                </Grid>
            </Grid>

            <style>
                {`
        @media print {
            body {
                font-family: Arial, sans-serif;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
             
            }
            th, td {
                border: 2px solid #ddd;
                padding: 8px;
                text-align: left;
                word-wrap: break-word;
                white-space: wrap;
            }
            th {
                background-color: #f4f4f4;
                font-weight: 900;
                text-wrap: nowrap
            }
            .report-header {
                text-align: center;
                margin-bottom: 20px;
            }
            .report-header img {
                max-width: 100px;
                margin-bottom: 10px;
            }
            .summary-row {
                font-weight: bold;
                background-color: #f9f9f9;
            }
            .exceeded-threshold {
                color: red;
                font-weight: bold;
            }
            .exceeded-threshold > span {
                display: block;
            }
            /* Apply word-wrap only for the 'description' column */
            .description-column {
                word-wrap: break-word;
                white-space: normal; /* Ensure it wraps correctly */
            }
        }
    `}
            </style>

            {trialBalance && trialBalance.length > 0 ? (
                <Grid item xs={12} ref={printRef}>
                    {printMode && (
                        <ReportHeader
                            orgInfo={(() => {
                                let reportsHeader = settings["reports-header"];
                                try {
                                    reportsHeader = JSON.parse(reportsHeader);
                                } catch (e) {
                                    reportsHeader = null;
                                }

                                return (
                                    reportsHeader || {
                                        name: {
                                            value: "Account Book",
                                            selected: true,
                                        },
                                        email: {
                                            value: "",
                                            selected: false,
                                        },
                                        contactNo: {
                                            value: "",
                                            selected: false,
                                        },
                                        address: {
                                            value: "",
                                            selected: false,
                                        },
                                        logoPos: {
                                            value: "left",
                                            selected: false,
                                        },
                                    }
                                );
                            })()}
                        />
                    )}
                    <TableContainer>
                        <Table size="small">
                            <TableHead
                                className={
                                    !printMode
                                        ? classes.stickyStyling
                                        : undefined
                                }
                            >
                                <TableRow
                                    className={
                                        !printMode
                                            ? classes.headerStyling
                                            : undefined
                                    }
                                >
                                    {columnsVisibility.SrNo && (
                                        <TableCell>SR No</TableCell>
                                    )}

                                    {columnsVisibility.Bank && (
                                        <TableCell>Bank</TableCell>
                                    )}
                                    {columnsVisibility.Balance && (
                                        <TableCell>Balance</TableCell>
                                    )}
                                    {columnsVisibility.Detail && (
                                        <TableCell>View Detail</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {trialBalance?.map((item: any, idx: number) => (
                                    <TableRow className={classes.hoverRow}>
                                        {columnsVisibility.SrNo && (
                                            <TableCell>{idx + 1}</TableCell>
                                        )}

                                        {columnsVisibility.Bank && (
                                            <TableCell>{item.name}</TableCell>
                                        )}

                                        {columnsVisibility.Balance && (
                                            <TableCell>{item.amount}</TableCell>
                                        )}
                                        {columnsVisibility.Detail && (
                                            <TableCell
                                                size="small"
                                                style={{
                                                    minWidth: "220px",
                                                }}
                                            >
                                                <Link
                                                    to={`/transactions?id=${item.id}`}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "white",
                                                        fontSize: "1px",
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        View Detail
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingTop: "1rem",
                            borderTop: `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <Typography variant="h6" align="right">
                            Total Balance: {currencyFormatter(totalBalance)}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Alert severity="info" style={{ width: "100%" }}>
                    No data found
                </Alert>
            )}
        </Grid>
    );
};

export default BankSummary;
