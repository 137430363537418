import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PaymentService } from "../../../../services/PaymentService";
import { Autocomplete } from "../../../Autocomplete/Autocomplete";
import Filteration from "../../../Filteration/Filteration";
import { RUDTable } from "../../../Table/RUDTable";
import UserContext from "../../../UserContext";
import { currencyFormatter } from "../../../constants";
import PaymentHeadView from "../../PaymentHeadView";
import PaymentImagePreview from "../../PaymentImagePreview";
import DescriptionTagsView from "../DescriptionTagsView";

const SingleClientCreditDebitHistory = () => {
    const { user } = useContext(UserContext);

    const [filter, setFilter] = useState({
        search: "",
        clientType: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
    });

    const theme = useTheme();

    const [creditDebitData, setCreditDebitData] = useState<any>();
    const [count, setCount] = useState(0);

    const [clearFilters, setClearFilters] = useState(false);

    const pagination_ref = useRef({ page: 0, limit: 0 });
    const sumOfPages_ref = useRef<{
        [page: number]: {
            currentPageCredit: number;
            currentPageDebit: number;
            currentPageBalance: number;
        };
    }>({});

    const createSummary = (cols: any) => {
        const summaryRow = ["Total"];
        let credit = 0;
        let debit = 0;
        let advance = 0;

        if (creditDebitData) {
            let currentPage = pagination_ref.current.page;

            // Calculate the totals for the current page
            let currentPageCredit = 0;
            let currentPageDebit = 0;
            let currentPageBalance = 0;

            for (const row of creditDebitData) {
                currentPageCredit += row["credit"];
                currentPageDebit += row["debit"];
                currentPageBalance = row["balance"]; // Assuming balance is the last value
            }

            sumOfPages_ref.current = {
                ...sumOfPages_ref.current,
                [currentPage]: {
                    currentPageCredit,
                    currentPageBalance,
                    currentPageDebit,
                },
            };

            console.log(sumOfPages_ref.current);
        }

        console.log(cols);
        // Aggregate totals from all stored pages
        for (const col of cols) {
            const currPage = pagination_ref.current.page;
            const sums = sumOfPages_ref.current;

            switch (col) {
                case "credit":
                    {
                        const currPageCredit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce((prev, [p, { currentPageCredit }]) => {
                                return prev + currentPageCredit;
                            }, 0);

                        summaryRow.push(currencyFormatter(currPageCredit));
                    }
                    break;

                case "debit":
                    {
                        const currPageDebit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce((prev, [p, { currentPageDebit }]) => {
                                return prev + currentPageDebit;
                            }, 0);

                        summaryRow.push(currencyFormatter(currPageDebit));
                    }
                    break;

                case "balance":
                    {
                        summaryRow.push(
                            currencyFormatter(
                                sums[currPage]?.currentPageBalance
                            )
                        );
                    }
                    break;

                default:
                    summaryRow.push("");
            }
        }

        return summaryRow;
    };

    const history = useHistory();
    const location = useLocation();

    function handlePaymentDetails(row: any) {
        console.log(row);

        history.push(
            `${location.pathname}/details/${row.IdView}/${user.baseUser.id}`
        );
    }

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            clientType: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
        });

        setCount(count + 1);
    };
    return (
        <div>
            <Grid container spacing={1} style={{ padding: "1rem" }}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/payment/method/get-All"
                        setOutput={(bank: any) => {
                            if (!bank) return;
                            setFilter({ ...filter, bank: bank.id });
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/payment/get-status"
                        setOutput={(des: any) => {
                            if (!des) return;
                            setFilter({
                                ...filter,
                                status: des.status,
                            });
                        }}
                        labelKey="label"
                        label="Status"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        style={{ height: "80%" }}
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            <RUDTable
                getter={(page, limit) => {
                    pagination_ref.current = { page, limit };
                    return PaymentService.getPayments(page, limit, {
                        search: filter.search ? filter.search : null,
                        client: user.baseUser.id,
                        clientType: filter.clientType
                            ? filter.clientType
                            : null,
                        // city: filter.client ? filter.client : null,
                        bank: filter.bank ? filter.bank : null,
                        startDate: filter.startDate ? filter.startDate : null,
                        endDate: filter.endDate ? filter.endDate : null,
                        status: filter.status ? filter.status : null,

                        // classroom: filter.classroom ? filter.classroom : null,
                    });
                }}
                readables={{
                    id: "Transaction Number",
                    name: "Payment Name",
                    client: "Client Name",
                    credit: "Credit",
                    debit: "Debit",
                    balance: "Balance",
                    bank: "Bank Name",
                    status: "Status",
                    description: "Description",
                    descriptionTags: "Description Tags",
                    paymentHeads: "Payment Heads",
                    showImage: "Show Image",
                    details: "Details",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    deletionId: r.id,
                    client: r?.client?.employee?.name,
                    clientId: r.client.id,
                    IdView: r.id,
                    id: "TRA-" + r.id,
                    bank: r?.bank?.name ? (
                        <Chip label={r.bank.name} size="small" />
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    description: r.description ? (
                        r.description
                    ) : (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    name: r?.name || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                    status:
                        r.status == "payment" &&
                        r.credit == 0 &&
                        r.debit >= 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(45deg, #7F0900, #FF1200)",
                                }}
                                label="Debit"
                            />
                        ) : r.status == "payment" &&
                          r.credit >= 0 &&
                          r.debit == 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(45deg, #45BF55, #00261C)",
                                }}
                                label="Credit"
                            />
                        ) : r.status == "adjustment" &&
                          r.credit >= 0 &&
                          r.debit == 0 ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(0deg,#F2AA00,#FF7C00)",
                                }}
                                label="Adjustment"
                            />
                        ) : r.status == "advance" ? (
                            <Chip
                                size="small"
                                style={{
                                    background:
                                        "linear-gradient(0deg, #023373, #0F9BF2)",
                                }}
                                label="Advance"
                            />
                        ) : (
                            r.status == "refund" && (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(0deg, #9C3E00, #502000)",
                                    }}
                                    label="Refund"
                                />
                            )
                        ),
                })}
                ops={{
                    read: "READ PAYMENT",
                    edit: "EDIT PAYMENT",
                    delete: "DELETE PAYMENT",
                }}
                updatingAgents={[filter.search, filter.apply, count]}
                editables={[]}
                customCols={[
                    {
                        header: "descriptionTags",
                        content: (row) => {
                            const desc =
                                row.descTagsComponent.length > 0 ? (
                                    <DescriptionTagsView row={row} />
                                ) : (
                                    <Typography
                                        style={{ color: theme.palette.divider }}
                                    >
                                        N/A
                                    </Typography>
                                );

                            return desc;
                        },
                    },

                    {
                        header: "paymentHeads",
                        content: (row) => {
                            return <PaymentHeadView row={row} />;
                        },
                    },

                    {
                        header: "showImage",
                        content: (row) => (
                            <PaymentImagePreview id={row.IdView} />
                        ),
                    },

                    {
                        header: "details",
                        content: (row) => (
                            <Tooltip title="Payment Details">
                                <IconButton
                                    onClick={() => handlePaymentDetails(row)}
                                    size="small"
                                >
                                    <InfoOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                ]}
                getRows={(rows) => {
                    return setCreditDebitData(rows);
                }}
                summaryRow={createSummary}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
                storageKey="singleClientCreditDebit"
            />
        </div>
    );
};

export default SingleClientCreditDebitHistory;

///f/f//f/ff
