import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Loadable from "react-loadable";
import { useHistory } from "react-router-dom";
import City from "../City/City";
import ClientType from "../ClientType/ClientType";
import DataImport from "../DataImport/DataImport";
import DescriptionTag from "../DescriptionTags/DescriptionTag";
import Loading from "../Loading";
import BankSummary from "../Payment/BankSummary";
import CreateBulkPayment from "../Payment/BulkPayment/CreateBulkPayment";
import CreateCollection from "../Payment/Collection/CreateCollection";
import Heads from "../Payment/Head/Heads";
import PaymentAgaintBank from "../Payment/PaymentAgainstBank";
import PaymentRecord from "../Payment/Payments/PaymentRecord";
import Payments from "../Payment/Payments/Payments";
import TrialBalance from "../Payment/TrialBalance";
import CreateRole from "../Rolesystem/CreateRole";
import ShowRoles from "../Rolesystem/ShowRoles";
import SimpleDashboard from "../SimpleDashboard/SimpleDashboard";
import UserContext from "../UserContext";
import Users from "../account-book/Users";
import Classrooms from "../account-book/classrooms";
import Employees from "../account-book/employees";
import PaymentMethod from "../paymentMethod/PaymentMethod";
import SimpleSidebarComponent from "./SimpleSidebarComponent";

const AsyncMultiPermissionAuthorize = Loadable({
    loader: () => import("../MultiPermissionAuthorize"),
    loading: Loading,
});

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const facultyModules = {
    Payment: [
        {
            name: "Payments",
            component: <Payments />,
            permissions: ["READ PAYMENT"],
            href: "/payment",
        },
    ],
};

export const studentModules = {};

export const userModules = {
    Dashboard: [
        {
            name: "Dashboard",
            component: <SimpleDashboard />,
            permissions: ["READ SIMPLE_DASHBOARD"],
            href: "/dashboard",
        },
    ],

    Rolesystem: [
        {
            name: "Create Role",
            component: <CreateRole />,
            permissions: ["CREATE ROLE"],
            href: "/create-role",
        },

        {
            name: "Roles",
            component: <ShowRoles />,
            permissions: ["READ ROLE"],
            href: "/roles",
        },
    ],

    Users: [
        {
            name: "Users",
            component: <Users />,
            permissions: ["READ USERS"],
            href: "/show-users",
        },
    ],

    Organization: [
        {
            name: "Classes",
            component: <Classrooms />,
            permissions: ["READ CLASSROOMS"],
            href: "/classrooms",
        },
    ],

    Client: [
        {
            name: "City",
            component: <City />,
            permissions: ["READ CITY"],
            href: "/city",
        },
        {
            name: "Client Type",
            component: <ClientType />,
            permissions: ["READ CLIENT_TYPE"],
            href: "/clientType",
        },

        {
            name: "Client",
            component: <Employees />,
            permissions: ["READ CLIENT"],
            href: "/client",
        },

        {
            name: "Import Client",
            component: <DataImport />,
            permissions: ["CREATE CLIENT"],
            href: "/import/client",
        },
    ],

    Payment: [
        {
            name: "Description Tags",
            component: <DescriptionTag />,
            permissions: [
                "CREATE DESCRIPTION_TAGS",
                "READ DESCRIPTION_TAGS",
                "EDIT DESCRIPTION_TAGS",
                "DELETE DESCRIPTION_TAGS",
            ],
            href: "/description-tags",
        },

        {
            name: "Bank",
            component: <PaymentMethod />,
            permissions: [
                "CREATE PAYMENT_METHOD",
                "READ PAYMENT_METHOD",
                "EDIT PAYMENT_METHOD",
                "DELETE PAYMENT_METHOD",
            ],
            href: "/payment-method",
        },
        {
            name: "Heads",
            component: <Heads />,
            permissions: [
                "CREATE PAYMENT_HEAD",
                "READ PAYMENT_HEAD",
                "EDIT PAYMENT_HEAD",
                "DELETE PAYMENT_HEAD",
            ],
            href: "/user/payment/head",
        },
        {
            name: "Payments",
            component: <Payments />,
            permissions: [
                "CREATE PAYMENT",
                "READ PAYMENT",
                "EDIT PAYMENT",
                "DELETE PAYMENT",
            ],
            href: "/user/payment",
        },

        {
            name: "Collections",
            component: <CreateCollection />,
            permissions: ["CREATE BULK_COLLECTION"],
            href: "/payment/collection",
        },

        {
            name: "BulK Payments",
            component: <CreateBulkPayment />,
            permissions: ["CREATE BULK_PAYMENT"],
            href: "/payment/bulk",
        },

        {
            name: "Trial Balance",
            component: <TrialBalance />,
            permissions: ["READ TRIAL_BALANCE"],
            href: "/payment/trial-balance",
        },

        {
            name: "Payment Record",
            component: <PaymentRecord />,
            permissions: ["READ PAYMENT_RECORD"],
            href: "/payment/record",
        },

        {
            name: "Transactions Summary",
            component: <PaymentAgaintBank />,
            permissions: ["READ PAYMENT_RECORD"],
            href: "/transactions",
        },

        {
            name: "Bank Balance",
            component: <BankSummary />,
            permissions: ["READ PAYMENT_RECORD"],
            href: "/payment/bank/summary",
        },
    ],
};

export default function Sidebar({ variant, setComponent }) {
    const classes = useStyles();
    const history = useHistory();
    const { user, permissions } = useContext(UserContext);
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState({});
    const modules = useMemo(() => {
        switch (variant) {
            case "employee":
                return facultyModules;
            case "student":
                return studentModules;
            default:
                return userModules;
        }
    }, [variant]);

    const handleSelect = (subModule) => {
        setSelected(subModule.name);
        setComponent(subModule.component);
    };

    useEffect(() => {
        Object.keys(modules).map((mod) => setOpen({ ...open, mod: false }));
    }, []);

    return (
        user && (
            <List>
                {permissions &&
                    user &&
                    Object.entries(modules)
                        // .sort((a, b) => b[1].length - a[1].length)
                        .map(([module, subModules], idx) =>
                            subModules.length === 1 ? (
                                <AsyncMultiPermissionAuthorize
                                    ops={subModules[0].permissions || []}
                                    strategy="some"
                                    key={`${module}-${idx}`}
                                >
                                    <SimpleSidebarComponent
                                        name={module}
                                        href={subModules[0].href}
                                    />
                                </AsyncMultiPermissionAuthorize>
                            ) : (
                                <Fragment key={idx}>
                                    {subModules
                                        .map((sub) => sub.permissions || [])
                                        .reduce((ops, op) => [...ops, ...op])
                                        .some((op) =>
                                            permissions.includes(op)
                                        ) ? (
                                        <ListItem
                                            button
                                            onClick={() =>
                                                setOpen({
                                                    ...open,
                                                    [module]: !open[[module]],
                                                })
                                            }
                                        >
                                            <ListItemText primary={module} />
                                            {open[[module]] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItem>
                                    ) : null}

                                    <Collapse
                                        in={open[module]}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List disablePadding dense>
                                            {subModules.map(
                                                (subModule, idx2) => (
                                                    <AsyncMultiPermissionAuthorize
                                                        ops={
                                                            subModule.permissions ||
                                                            []
                                                        }
                                                        key={idx2}
                                                        strategy="some"
                                                    >
                                                        <ListItem
                                                            selected={
                                                                selected ===
                                                                subModule.name
                                                            }
                                                            button
                                                            className={
                                                                classes.nested
                                                            }
                                                            onClick={() => {
                                                                handleSelect(
                                                                    subModule
                                                                );
                                                                history.push(
                                                                    subModule.href
                                                                );
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    subModule.name
                                                                }
                                                            />
                                                        </ListItem>
                                                    </AsyncMultiPermissionAuthorize>
                                                )
                                            )}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            )
                        )}
            </List>
        )
    );
}
