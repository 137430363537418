import {
    Checkbox,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    LinearProgress,
    makeStyles,
    Menu,
    MenuItem,
    MenuList,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import {
    ArchiveOutlined,
    DeleteForeverOutlined,
    EditOutlined,
    Refresh,
    ViewColumn,
    ViewColumnOutlined,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Box } from "@mui/material";
import {
    CSSProperties,
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Authorize from "../Authorize";
import { AuthorizeFallback } from "../AuthorizeFallback";
import ConfirmDelete from "../ConfirmAction";
import PrintTable from "../export/PrintTable";
import { ParseTags } from "../ParseTags";
import { ReportHeader } from "../settings/Header/ReportHeader";
import UserContext from "../UserContext";
import { EditInRUD } from "./Edit";
import { Footer } from "./Footer";

type Props = {
    getter: (...args: any) => Promise<any[]>;
    editor?: (...args: any) => Promise<any[]>;
    deletor?: (ids: (string | number)[], row: any) => Promise<any[]>;
    rowsPreprocessor?: (row: any) => any;
    ops: {
        read: string;
        edit?: string;
        delete?: string;
    };
    opsMessages?: {
        read?: string;
        edit?: string;
        delete?: string;
    };
    readables: { [key: string]: string };
    editables?: {
        name: string;
        type: "textfield" | "select" | "autocomplete" | "dateType";
        fieldProps?: any;
        isRequired?: boolean;
        label: string;
        permission?: string;

        options?: {
            value: any;
            label: string;
            valuesFetcher: (rowToEdit: any) => Promise<any[]>;
        };

        autocomplete?: {
            api?: string;
            labelKey: string;
            apiParams?: any;
            defaultValue?: any;
            label: String;
            defaultOptions?: any;
            preprocessor?: (row: any) => any;
        };
    }[];
    updatingAgents?: any[];
    parseTags?: boolean;
    deleteForever?: any;
    customCols?: { header: string; content: (row: any) => any }[];
    filters?: JSX.Element;
    selectedFilters?: ReactElement;
    defaultSelected?: string[];
    getSelectedRows?: (rows: any[]) => void;
    actions?: JSX.Element[];
    summaryRow?: (cols: string[]) => any[];
    getRows?: (rows: any[]) => void;
    storageKey?: string;
    additionalPrintInfo?: any;
    colStyles?: { [key: string]: CSSProperties };
};

const initialFeedback = {
    hidden: true,
    message: "",
    severity: "success",
    loading: false,
};

const useStyles = makeStyles((theme) => ({
    feedback: {
        margin: theme.spacing(2),
    },
    noPadding: {
        padding: 0,
    },
    hoverRow: {
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.3)", // grey.300 equivalent
        },
    },

    headerStyling: {
        textTransform: "capitalize",
        "& > *": {
            backgroundColor: theme.palette.common.black,
            fontWeight: "bold",
        },
    },
}));

export const RUDTable = ({
    rowsPreprocessor = (rows) => rows,
    updatingAgents = [],
    defaultSelected = [],
    parseTags = false,
    summaryRow,
    additionalPrintInfo,
    colStyles = {},
    ...props
}: Props) => {
    const classes = useStyles();
    const [rows, setRows] = useState<any[]>([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 500 });
    const [feedback, setFeedback] = useState(initialFeedback);
    const [editOpen, setEditOpen] = useState(false);
    const [rowToEdit, setRowToEdit] = useState<null | any>(null);
    const [printMode, setPrintMode] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>({
        item: null,
        open: false,
    });

    const [selectedCols, setSelectedCols] = useState<string[]>(defaultSelected);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [changeCount, setChangeCount] = useState(0);
    const theme = useTheme();
    const tableRef = useRef<HTMLTableElement>(null);

    const { settings } = useContext(UserContext);

    let calculatedNumber: any = [];

    const allChecked = useMemo(() => {
        const rowsIds = rows.map((row) => row.id);
        const selectedRowsIds = selectedRows.map((row) => row.id);

        const checked = rowsIds
            .map((rowId) => selectedRowsIds.includes(rowId))
            .every((rowCheck) => rowCheck);

        return checked;
    }, [selectedRows, rows]);

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
        setSelectedRows([]);
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const fetchRowsAndCount = async () => {
        setFeedback({ ...initialFeedback, loading: true });
        const [data, err] = await props.getter(
            pagination.page + 1,
            pagination.limit
        );

        if (data) {
            const processedRows = data.rows.map((r: any) =>
                rowsPreprocessor(r)
            );
            setRows(processedRows);
            props.getRows && props.getRows(processedRows);
            setRowsCount(data.count);
            setFeedback({
                hidden: true,
                message: data.message,
                severity: "success",
                loading: false,
            });
        } else {
            setRows([]);
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });
        }
    };

    const handleDeleteRow = async (id: number, row: any) => {
        if (!props.deletor) {
            return;
        }

        setFeedback({
            hidden: true,
            message: "",
            severity: "success",
            loading: true,
        });

        const [data, err] = await props.deletor([id], row);

        if (data) {
            setFeedback({
                hidden: false,
                message: data.message,
                severity: "success",
                loading: false,
            });
            setChangeCount((n: number) => n + 1);
        } else {
            setFeedback({
                hidden: false,
                message: err,
                severity: "error",
                loading: false,
            });
        }
    };

    const handleEditRow = async (row: any) => {
        setRowToEdit(row);
        setEditOpen(true);
    };

    const handleSelectAllRows = (check: boolean) => {
        if (check) {
            setSelectedRows(
                (currentSelected) => rows
                // [
                //     ...currentSelected,
                //     ...rows.filter(
                //         (r) =>
                //             !currentSelected
                //                 .map((selectedRow) => selectedRow.id)
                //                 .includes(r.id)
                //     ),
                // ]
            );

            if (props.getSelectedRows) props.getSelectedRows(rows);
        } else {
            setSelectedRows((currentSelected) => {
                const filteredRows = currentSelected.filter(
                    (selectedRow) =>
                        !rows.map((r) => r.id).includes(selectedRow.id)
                );

                if (props.getSelectedRows) props.getSelectedRows(filteredRows);

                return filteredRows;
            });
        }
    };

    const handleSingleRowSelect = (check: boolean, row: any) => {
        if (check) {
            setSelectedRows((currentSelected) => {
                const updatedRows = [...currentSelected, row];

                if (props.getSelectedRows) props.getSelectedRows(updatedRows);

                return updatedRows;
            });
        } else {
            setSelectedRows((currentSeletect) => {
                const filteredRows = currentSeletect.filter(
                    (sr) => sr.id !== row.id
                );

                if (props.getSelectedRows) props.getSelectedRows(filteredRows);

                return filteredRows;
            });
        }
    };

    useEffect(() => {
        fetchRowsAndCount();
    }, [pagination, ...updatingAgents, changeCount]);

    useEffect(() => {
        const savedCols = localStorage.getItem(props?.storageKey as any);
        if (savedCols) {
            const savedSelected = JSON.parse(savedCols);
            const orderedSelected = Object.keys(props?.readables).filter(
                (col) => savedSelected?.includes(col)
            );

            setSelectedCols(orderedSelected);
        } else {
            setSelectedCols(Object.keys(props.readables));
        }
    }, [props.storageKey, props.readables]);

    return (
        <Authorize
            opName={props.ops.read}
            fallback={
                props.opsMessages?.read ? (
                    <AuthorizeFallback message={props.opsMessages?.read} />
                ) : null
            }
        >
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                style={{
                    padding: theme.spacing(2),
                    width: "100%",
                }}
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {props.filters && props.filters}

                        <Hidden smDown>
                            <PrintTable
                                printMode={printMode}
                                setPrintMode={setPrintMode}
                                componentRef={tableRef}
                            />
                        </Hidden>

                        <RUDTableCols
                            readables={props.readables}
                            selected={selectedCols}
                            setSelected={setSelectedCols}
                            strorageKey={props.storageKey}
                        />

                        <Tooltip title="Refresh">
                            <IconButton
                                onClick={() => {
                                    setChangeCount((n) => n + 1);
                                }}
                            >
                                <Refresh />
                            </IconButton>
                        </Tooltip>

                        {props.actions && props.actions.map((action) => action)}
                    </div>

                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100, 250, 500]}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        count={rowsCount}
                        rowsPerPage={pagination.limit}
                        component="div"
                        page={pagination.page}
                        style={{ flex: "1" }}
                    />
                </Grid>
            </Grid>

            {rowToEdit && props.editables && props.editor && (
                <EditInRUD
                    row={rowToEdit}
                    editables={props.editables as any}
                    open={editOpen}
                    setOpen={setEditOpen}
                    editor={props.editor}
                    setChangeCount={setChangeCount}
                />
            )}

            <div ref={tableRef}>
                {printMode && (
                    <ReportHeader
                        orgInfo={(() => {
                            let reportsHeader = settings["reports-header"];
                            try {
                                reportsHeader = JSON.parse(reportsHeader);
                            } catch (e) {
                                reportsHeader = null;
                            }

                            return (
                                reportsHeader || {
                                    name: {
                                        value: "Account Book",
                                        selected: true,
                                    },
                                    email: { value: "", selected: false },
                                    contactNo: { value: "", selected: false },
                                    address: { value: "", selected: false },
                                    logoPos: { value: "left", selected: false },
                                }
                            );
                        })()}
                    />
                )}

                {printMode && props.storageKey == "CreditDebit" && (
                    <Box px={"2rem"} fontWeight={900}>
                        {additionalPrintInfo?.name && (
                            <Box display={"flex"} gap={2}>
                                <Typography variant="subtitle2">
                                    Client Name:{" "}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {additionalPrintInfo.name}
                                </Typography>
                            </Box>
                        )}

                        {additionalPrintInfo?.mobile && (
                            <Box display={"flex"} gap={2}>
                                <Typography variant="subtitle2">
                                    Phone:
                                </Typography>
                                <Typography variant="subtitle2">
                                    {additionalPrintInfo.mobile}
                                </Typography>
                            </Box>
                        )}

                        {additionalPrintInfo?.address && (
                            <Box display={"flex"} gap={2}>
                                <Typography variant="subtitle2">
                                    Address:{" "}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {additionalPrintInfo.address}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                <style>
                    {`
        @media print {
            body {
                font-family: Arial, sans-serif;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
             
            }
            th, td {
                border: 2px solid #ddd;
                padding: 8px;
                text-align: left;
                word-wrap: break-word;
                white-space: wrap;
            }
            th {
                background-color: #f4f4f4;
                font-weight: 900;
                text-wrap: nowrap
            }
            .report-header {
                text-align: center;
                margin-bottom: 20px;
            }
            .report-header img {
                max-width: 100px;
                margin-bottom: 10px;
            }
            .summary-row {
                font-weight: bold;
                background-color: #f9f9f9;
            }
            .exceeded-threshold {
                color: red;
                font-weight: bold;
            }
            .exceeded-threshold > span {
                display: block;
            }
            /* Apply word-wrap only for the 'description' column */
            .description-column {
                word-wrap: break-word;
                white-space: normal; /* Ensure it wraps correctly */
            }
        }
    `}
                </style>

                <TableContainer
                    style={{
                        padding: printMode ? "2rem" : "0 !important",
                        maxHeight: printMode ? undefined : "80svh", // Add scroll container height
                        overflow: printMode ? undefined : "auto", // Enable scrolling
                    }}
                >
                    <div>
                        {feedback.loading ? <LinearProgress /> : null}
                        <section
                            hidden={feedback.hidden}
                            className={classes.feedback}
                        >
                            <Alert severity={feedback.severity as any}>
                                {feedback.message}
                            </Alert>
                        </section>
                    </div>

                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow
                                className={
                                    !printMode
                                        ? classes.headerStyling
                                        : undefined
                                }
                            >
                                {props.getSelectedRows && (
                                    <TableCell size="small">
                                        <Tooltip title="Select All">
                                            <FormControl>
                                                <Checkbox
                                                    color="primary"
                                                    checked={allChecked}
                                                    onChange={(e, check) =>
                                                        handleSelectAllRows(
                                                            check
                                                        )
                                                    }
                                                    indeterminate={Boolean(
                                                        !allChecked &&
                                                            selectedRows.length
                                                    )}
                                                />
                                            </FormControl>
                                        </Tooltip>
                                    </TableCell>
                                )}

                                <TableCell size="small">S No.</TableCell>

                                {selectedCols.map((col, idx) => (
                                    <TableCell
                                        key={col}
                                        size="small"
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {props.readables[col]}
                                    </TableCell>
                                ))}

                                {!printMode && (
                                    <>
                                        {props.editor && props.editables && (
                                            <Authorize
                                                opName={props.ops.edit}
                                                fallback={
                                                    props.opsMessages?.edit ? (
                                                        <AuthorizeFallback
                                                            message={
                                                                props
                                                                    .opsMessages
                                                                    ?.edit
                                                            }
                                                        />
                                                    ) : null
                                                }
                                            >
                                                <TableCell size="small">
                                                    Edit
                                                </TableCell>
                                            </Authorize>
                                        )}
                                        <Authorize
                                            opName={props.ops.delete}
                                            fallback={
                                                props.opsMessages?.delete ? (
                                                    <AuthorizeFallback
                                                        message={
                                                            props.opsMessages
                                                                ?.delete
                                                        }
                                                    />
                                                ) : null
                                            }
                                        >
                                            {props.deletor ? (
                                                <TableCell size="small">
                                                    {props.deleteForever
                                                        ? "Delete"
                                                        : "Archive"}
                                                </TableCell>
                                            ) : null}
                                        </Authorize>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    selected={selectedRows
                                        .map((row) => row.id)
                                        .includes(row.id)}
                                    className={classes.hoverRow}
                                >
                                    {props.getSelectedRows && (
                                        <TableCell size="small">
                                            <FormControl>
                                                <Checkbox
                                                    color="primary"
                                                    checked={selectedRows
                                                        .map((row) => row.id)
                                                        .includes(row.id)}
                                                    onChange={(e, check) =>
                                                        handleSingleRowSelect(
                                                            check,
                                                            row
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                        </TableCell>
                                    )}

                                    <TableCell size="small">
                                        {pagination.page > 0
                                            ? pagination.page *
                                                  pagination.limit +
                                              idx +
                                              1
                                            : idx + 1}
                                    </TableCell>

                                    {selectedCols.map((col, idx2) => (
                                        <TableCell
                                            key={col}
                                            size="small"
                                            style={{
                                                whiteSpace: printMode
                                                    ? undefined
                                                    : "nowrap",
                                                ...colStyles[col],
                                            }}
                                        >
                                            {parseTags ? (
                                                <ParseTags text={row[col]} />
                                            ) : row[col] || row[col] == 0 ? (
                                                row[col]
                                            ) : row ? (
                                                (props.customCols ?? [])
                                                    .find(
                                                        ({
                                                            header,
                                                            content,
                                                        }) => {
                                                            if (
                                                                header === col
                                                            ) {
                                                                console.log(
                                                                    header,
                                                                    col,
                                                                    content
                                                                );
                                                            }

                                                            return (
                                                                header === col
                                                            );
                                                        }
                                                    )
                                                    ?.content(row)
                                            ) : (
                                                0
                                            )}
                                        </TableCell>
                                    ))}

                                    {!printMode && (
                                        <>
                                            {props.editor &&
                                                props.editables && (
                                                    <Authorize
                                                        opName={props.ops.edit}
                                                        fallback={
                                                            props.opsMessages
                                                                ?.edit ? (
                                                                <AuthorizeFallback
                                                                    message={
                                                                        props
                                                                            .opsMessages
                                                                            ?.edit
                                                                    }
                                                                />
                                                            ) : null
                                                        }
                                                    >
                                                        <TableCell
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            size="small"
                                                            onClick={() =>
                                                                handleEditRow(
                                                                    row
                                                                )
                                                            }
                                                            title="Edit"
                                                        >
                                                            <EditOutlined />
                                                        </TableCell>
                                                    </Authorize>
                                                )}
                                            {props.deletor && (
                                                <Authorize
                                                    opName={props.ops.delete}
                                                    fallback={
                                                        props.opsMessages
                                                            ?.delete ? (
                                                            <AuthorizeFallback
                                                                message={
                                                                    props
                                                                        .opsMessages
                                                                        ?.delete
                                                                }
                                                            />
                                                        ) : null
                                                    }
                                                >
                                                    <TableCell
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            setDeleteItem({
                                                                item: row.deletionId,
                                                                open: true,
                                                            })
                                                        }
                                                        title="Archive/Delete"
                                                    >
                                                        {props.deleteForever ? (
                                                            <DeleteForeverOutlined fontSize="small" />
                                                        ) : (
                                                            <ArchiveOutlined fontSize="small" />
                                                        )}
                                                    </TableCell>
                                                </Authorize>
                                            )}
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>

                        {summaryRow && (
                            <TableRow
                                className={
                                    !printMode
                                        ? classes.headerStyling
                                        : undefined
                                }
                            >
                                {summaryRow(selectedCols).map((summary) => (
                                    <TableCell
                                        size="small"
                                        style={{ font: "caption" }}
                                    >
                                        {summary}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}

                        {printMode && <Footer span={selectedCols.length + 1} />}
                    </Table>
                </TableContainer>
            </div>

            <ConfirmDelete
                open={deleteItem.open}
                setOpen={(v: boolean) =>
                    setDeleteItem({ ...deleteItem, open: v })
                }
                next={() =>
                    handleDeleteRow(
                        deleteItem.item,
                        rows.find((row) => row.deletionId == deleteItem.item)
                    )
                }
                actionOpts={
                    props.deleteForever
                        ? undefined
                        : {
                              accept: "Archive",
                              reject: "Cancel",
                              title: "Archive",
                              text: "Are you sure you want to archive this item? it will be available in the archive section",
                          }
                }
            />
        </Authorize>
    );
};

type RUDTableColsProps = {
    readables: Object;
    selected: string[];
    setSelected: Dispatch<SetStateAction<string[]>>;
    strorageKey?: any;
};

const RUDTableCols = ({
    readables,
    selected,
    setSelected,
    strorageKey,
}: RUDTableColsProps) => {
    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const theme = useTheme();

    const handleColumnToggle = (key: string) => {
        let updatedSelected: any;

        if (selected.includes(key)) {
            updatedSelected = selected.filter((col) => col !== key);
        } else {
            updatedSelected = [...selected, key];
        }

        // Preserve the order based on the original readables keys
        const orderedSelected = Object.keys(readables).filter((col: any) =>
            updatedSelected.includes(col)
        );

        setSelected(orderedSelected);

        // Save to localStorage
        localStorage.setItem(strorageKey, JSON.stringify(orderedSelected));
    };
    return (
        <>
            <Tooltip title="Select Columns">
                <IconButton
                    ref={showColumnsBtn}
                    onClick={() => setShowColumns(true)}
                >
                    {showColumns ? (
                        <ViewColumn htmlColor={theme.palette.primary.main} />
                    ) : (
                        <ViewColumnOutlined />
                    )}
                </IconButton>
            </Tooltip>

            <Menu
                open={showColumns}
                anchorEl={showColumnsBtn.current}
                onClose={() => setShowColumns(false)}
                elevation={4}
                variant="menu"
            >
                <MenuList
                    disablePadding
                    style={{ padding: theme.spacing(0, 1) }}
                >
                    {Object.entries(readables).map(([key, col]) => (
                        <MenuItem
                            key={key}
                            value={key}
                            selected={selected.includes(key)}
                            style={{ paddingLeft: 0 }}
                            onClick={() => handleColumnToggle(key)}
                        >
                            <Checkbox
                                size="small"
                                checked={selected.includes(key)}
                                color="primary"
                            />

                            <Typography>{col}</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
};
