import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { PaymentService } from "../../services/PaymentService";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { currencyFormatter, dateFormatter } from "../constants";
import PrintTable from "../export/PrintTable";
import { ReportHeader } from "../settings/Header/ReportHeader";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    feedback: {
        margin: theme.spacing(2),
    },
    noPadding: {
        padding: 0,
    },
    hoverRow: {
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.3)", // grey.300 equivalent
        },
    },

    headerStyling: {
        textTransform: "capitalize",
        "& > *": {
            backgroundColor: theme.palette.common.black,
            fontWeight: "bold",
        },
    },
}));

const PaymentAgaintBank = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });
    const [dataWithBalance, setDataWithBalance] = useState([]);

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        SrNo: true,
        Transaction: true,
        Client: true,
        Date: true,
        Credit: true,
        Debit: true,
        Balance: true,
        Description: true,
        Bank: true,
    });
    const printRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const [printMode, setPrintMode] = useState(false);
    const [paymentToBePaid, setPaymentToBePaid] = useState<any>();
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 500,
    });
    const { settings } = useContext(UserContext);
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();
    const [balanceSum, setBalanceSum] = useState<{ [page: number]: number }>(
        {}
    );

    const sumOfPages_ref = useRef<{
        [page: number]: {
            currentPageCredit: number;
            currentPageDebit: number;
            currentPageBalance: number;
        };
    }>({});

    // const createSummary = (columnsVisibility: any) => {
    //     const summaryRow: any[] = [];

    //     if (dataWithBalance?.length) {
    //         let currentPage = pagination.page;

    //         // Calculate the totals for the current page
    //         let currentPageCredit = 0;
    //         let currentPageDebit = 0;
    //         let currentPageBalance = 0;

    //         for (const row of dataWithBalance) {
    //             currentPageCredit += row["credit"];
    //             currentPageDebit += row["debit"];
    //             currentPageBalance = row["balance"]; // Assuming balance is the last value
    //         }

    //         sumOfPages_ref.current = {
    //             ...sumOfPages_ref.current,
    //             [currentPage]: {
    //                 currentPageCredit,
    //                 currentPageBalance,
    //                 currentPageDebit,
    //             },
    //         };
    //     }

    //     for (const col of Object.keys(columnsVisibility)) {
    //         const currPage = pagination.page;
    //         const sums = sumOfPages_ref.current;

    //         console.log(sums);

    //         switch (col) {
    //             case "Credit":
    //                 {
    //                     const currPageCredit = Object.entries(sums)
    //                         .filter(([page]) => parseInt(page) <= currPage)
    //                         .reduce((prev, [p, { currentPageCredit }]) => {
    //                             return prev + currentPageCredit;
    //                         }, 0);

    //                     summaryRow.push(currencyFormatter(currPageCredit));
    //                 }
    //                 break;

    //             case "Debit":
    //                 {
    //                     const currPageDebit = Object.entries(sums)
    //                         .filter(([page]) => parseInt(page) <= currPage)
    //                         .reduce((prev, [p, { currentPageDebit }]) => {
    //                             return prev + currentPageDebit;
    //                         }, 0);

    //                     summaryRow.push(currencyFormatter(currPageDebit));
    //                 }
    //                 break;

    //             case "Balance":
    //                 {
    //                     summaryRow.push(
    //                         currencyFormatter(
    //                             sums[currPage]?.currentPageBalance
    //                         )
    //                     );
    //                 }
    //                 break;

    //             default:
    //                 summaryRow.push("");
    //         }
    //     }

    //     const colTotals = {
    //         Credit: summaryRow[4],
    //         Debit: summaryRow[5],
    //         Balance: summaryRow[6],
    //     };

    //     console.log(summaryRow);

    //     return summaryRow;
    // };

    const createSummary = (columnsVisibility: any) => {
        const summaryRow: any[] = [];

        if (dataWithBalance?.length) {
            let currentPage = pagination.page;

            // Calculate the totals for the current page
            let currentPageCredit = 0;
            let currentPageDebit = 0;
            let currentPageBalance = 0;

            for (const row of dataWithBalance) {
                currentPageCredit += row["credit"];
                currentPageDebit += row["debit"];
                currentPageBalance = row["balance"]; // Assuming balance is the last value
            }

            sumOfPages_ref.current = {
                ...sumOfPages_ref.current,
                [currentPage]: {
                    currentPageCredit,
                    currentPageBalance,
                    currentPageDebit,
                },
            };
        }

        const currPage = pagination.page;
        const sums = sumOfPages_ref.current;

        // Get the visible columns in the same order as they appear in `columnsVisibility`
        const visibleColumns = Object.keys(columnsVisibility).filter(
            (col) => columnsVisibility[col]
        );

        for (const col of visibleColumns) {
            switch (col) {
                case "Credit":
                    {
                        const currPageCredit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce(
                                (prev, [_, { currentPageCredit }]) =>
                                    prev + currentPageCredit,
                                0
                            );

                        summaryRow.push(currencyFormatter(currPageCredit));
                    }
                    break;

                case "Debit":
                    {
                        const currPageDebit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce(
                                (prev, [_, { currentPageDebit }]) =>
                                    prev + currentPageDebit,
                                0
                            );

                        summaryRow.push(currencyFormatter(currPageDebit));
                    }
                    break;

                case "Balance":
                    {
                        summaryRow.push(
                            currencyFormatter(
                                sums[currPage]?.currentPageBalance || 0
                            )
                        );
                    }
                    break;

                default:
                    summaryRow.push(""); // Keep empty cells for non-summary columns
            }
        }

        return summaryRow;
    };

    const [trialBalance, setTrialBalance] = useState<any>();
    const ref = useRef(0);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "paymentAgainstBank",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const getTrialBalance = async () => {
        setLoading(true);
        const [data, err] =
            await PaymentService.getDetailCreditPaymentsAgainstBank(
                pagination.page + 1,
                pagination.limit,
                {
                    bank: filter.bank ? filter.bank : null,
                    startDate: filter.startDate ? filter.startDate : null,
                    endDate: filter.endDate ? filter.endDate : null,
                }
            );

        if (data?.rows.length) {
            setTrialBalance(data?.rows);
            const rowsCreditSum = data.rows.reduce(
                (prev: number, curr: any) => {
                    return (prev + curr.credit) as number;
                },
                0
            ) as number;

            setBalanceSum((curr) => ({
                ...curr,
                [pagination.page]: rowsCreditSum,
            }));

            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });

            setLoading(false);
        } else {
            setPaymentToBePaid([]);
            setTrialBalance([]);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTrialBalance();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("paymentAgainstBank");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                SrNo: true,
                Transaction: true,
                Client: true,
                Date: true,
                Credit: true,
                Debit: true,
                Balance: true,
                Description: true,
                Bank: true,
            });
        }
    }, []);

    useEffect(() => {
        // Get the last balance from the previous page, default to 0 if not found
        const lastPageBalance = balanceSum[pagination.page - 1] || 0;
        let runningBalance = lastPageBalance; // Start from the last page's balance

        const updatedRows = trialBalance?.map((row: any) => {
            // Calculate the new balance by adding credit and subtracting debit
            runningBalance += row.credit - row.debit;

            return {
                ...row,
                balance: runningBalance, // Store the running balance for each row
            };
        });

        setDataWithBalance(updatedRows); // Update the state with the new balances

        // Avoid redundant updates to balanceSum
        setBalanceSum((prev) => {
            if (prev[pagination.page] === runningBalance) return prev; // Prevent unnecessary state updates
            return {
                ...prev,
                [pagination.page]: runningBalance, // Store the last balance for this page
            };
        });
    }, [trialBalance, pagination.page]); // Remove `balanceSum` from dependencies

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        if (Boolean(id)) {
            setFilter((prevFilter: any) => ({
                ...prevFilter,
                bank: id,
                apply: prevFilter.apply + 1, // Ensure apply updates correctly
            }));
        }
    }, [location]);

    useEffect(() => {
        if (filter.apply > 0) {
            getTrialBalance();
        }
    }, [filter.apply]);

    return (
        <Grid container spacing={1} style={{ padding: "1rem" }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                    <Autocomplete
                        api="/payment/method/get-All"
                        setOutput={(bank: any) => {
                            if (!bank) return;
                            setFilter({ ...filter, bank: bank.id });
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={1}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            ></Grid>

            <Grid container style={{ justifyContent: "space-between" }}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size="small"
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Hidden smDown>
                            <PrintTable
                                componentRef={printRef}
                                setPrintMode={setPrintMode}
                            />
                        </Hidden>

                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                            rowsPerPageOptions={[100, 250, 500]}
                        />
                    </Box>
                </Grid>
            </Grid>

            <style>
                {`
        @media print {
            body {
                font-family: Arial, sans-serif;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
             
            }
            th, td {
                border: 2px solid #ddd;
                padding: 8px;
                text-align: left;
                word-wrap: break-word;
                white-space: wrap;
            }
            th {
                background-color: #f4f4f4;
                font-weight: 900;
                text-wrap: nowrap
            }
            .report-header {
                text-align: center;
                margin-bottom: 20px;
            }
            .report-header img {
                max-width: 100px;
                margin-bottom: 10px;
            }
            .summary-row {
                font-weight: bold;
                background-color: #f9f9f9;
            }
            .exceeded-threshold {
                color: red;
                font-weight: bold;
            }
            .exceeded-threshold > span {
                display: block;
            }
            /* Apply word-wrap only for the 'description' column */
            .description-column {
                word-wrap: break-word;
                white-space: normal; /* Ensure it wraps correctly */
            }
        }
    `}
            </style>

            {loading ? (
                <Box
                    style={{
                        paddingLeft: "50%",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : dataWithBalance.length == 0 ? (
                <Alert severity="info" style={{ width: "100%" }}>
                    {" "}
                    No Transactions Found
                </Alert>
            ) : (
                <Grid item xs={12} ref={printRef}>
                    {printMode && (
                        <ReportHeader
                            orgInfo={(() => {
                                let reportsHeader = settings["reports-header"];
                                try {
                                    reportsHeader = JSON.parse(reportsHeader);
                                } catch (e) {
                                    reportsHeader = null;
                                }

                                return (
                                    reportsHeader || {
                                        name: {
                                            value: "Account Book",
                                            selected: true,
                                        },
                                        email: {
                                            value: "",
                                            selected: false,
                                        },
                                        contactNo: {
                                            value: "",
                                            selected: false,
                                        },
                                        address: {
                                            value: "",
                                            selected: false,
                                        },
                                        logoPos: {
                                            value: "left",
                                            selected: false,
                                        },
                                    }
                                );
                            })()}
                        />
                    )}
                    <TableContainer
                        style={{
                            width: "100%",

                            padding: printMode ? "2rem" : "0 !important",
                            maxHeight: printMode ? undefined : "80svh", // Add scroll container height
                            overflow: printMode ? undefined : "auto", // Enable scrolling,
                        }}
                    >
                        <Table
                            size="small"
                            stickyHeader
                            // style={{ border: "1px solid yellow" }}
                        >
                            <TableHead>
                                <TableRow
                                    className={
                                        !printMode
                                            ? classes.headerStyling
                                            : undefined
                                    }
                                >
                                    {columnsVisibility.SrNo && (
                                        <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            SR No
                                        </TableCell>
                                    )}

                                    {columnsVisibility.Transaction && (
                                        <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Transaction Number
                                        </TableCell>
                                    )}

                                    {columnsVisibility.Date && (
                                        <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {" "}
                                            Transaction Date
                                        </TableCell>
                                    )}

                                    {columnsVisibility.Client && (
                                        <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Client
                                        </TableCell>
                                    )}
                                    {columnsVisibility.Credit && (
                                        <TableCell>Credit</TableCell>
                                    )}

                                    {columnsVisibility.Debit && (
                                        <TableCell>Debit</TableCell>
                                    )}

                                    {columnsVisibility.Balance && (
                                        <TableCell>Balance</TableCell>
                                    )}

                                    {columnsVisibility.Description && (
                                        <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            Description
                                        </TableCell>
                                    )}

                                    {columnsVisibility.Bank && (
                                        <TableCell>Bank</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {dataWithBalance?.map(
                                    (item: any, idx: number) => (
                                        <TableRow className={classes.hoverRow}>
                                            {columnsVisibility.SrNo && (
                                                <TableCell>{idx + 1}</TableCell>
                                            )}

                                            {columnsVisibility.Transaction && (
                                                <TableCell
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {"TRA-" + item.id}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Date && (
                                                <TableCell
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {dateFormatter.format(
                                                        new Date(item.createdAt)
                                                    )}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Client && (
                                                <TableCell
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {item.client.employee.name}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Credit && (
                                                <TableCell>
                                                    {item.credit}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Debit && (
                                                <TableCell>
                                                    {item.debit}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Balance && (
                                                <TableCell>
                                                    {item.balance}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Description && (
                                                <TableCell
                                                    style={{
                                                        whiteSpace: printMode
                                                            ? undefined
                                                            : "nowrap",
                                                    }}
                                                >
                                                    {item.description ? (
                                                        item.description
                                                    ) : (
                                                        <Typography
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .divider,
                                                            }}
                                                        >
                                                            N/A
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Bank && (
                                                <TableCell>
                                                    {item?.bank?.name}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                )}

                                <TableRow
                                    className={
                                        !printMode
                                            ? classes.headerStyling
                                            : undefined
                                    }
                                >
                                    {createSummary(columnsVisibility).map(
                                        (summary, index) => (
                                            <TableCell key={index} size="small">
                                                {summary}
                                            </TableCell>
                                        )
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default PaymentAgaintBank;
