import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Archive, Check, InfoOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Close } from "@mui/icons-material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DescriptionTagsService } from "../../../../services/DescriptionTagsService";
import { PaymentService } from "../../../../services/PaymentService";
import { allFaculty } from "../../../../services/employee.service";
import { Autocomplete } from "../../../Autocomplete/Autocomplete";
import Filteration from "../../../Filteration/Filteration";
import MultiPermissionAuthorize from "../../../MultiPermissionAuthorize";
import { RUDTable } from "../../../Table/RUDTable";
import UserContext from "../../../UserContext";
import { currencyFormatter, dateFormatter } from "../../../constants";
import PaymentHeadView from "../../PaymentHeadView";
import PaymentImagePreview from "../../PaymentImagePreview";
import DescriptionTagsView from "../DescriptionTagsView";
import UpdateReceipt from "../UpdateReceipt";

const useStyles = makeStyles((theme) => ({
    tooltipSize: {
        "& .MuiTooltip-tooltip": {
            fontSize: "10px", // Adjust tooltip text size
            padding: "4px 8px", // Adjust padding for a compact size
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};
const CreditDebitHistory = () => {
    const classes = useStyles();
    const { permissions } = useContext(UserContext);
    const [feedback, setFeedback] = useState(initialFeedback);

    const [filter, setFilter] = useState({
        id: "",
        search: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
    });

    const [additionalInfo, setAdditionalInfo] = useState<any>({
        name: "",
        mobile: "",
        address: "",
    });

    const theme = useTheme();

    const [creditDebitData, setCreditDebitData] = useState<any>();
    const [count, setCount] = useState(0);
    const [openDescriptionTag, setOpenDescriptionTAg] = useState<any>("");
    const [descriptionTags, setDescriptionTags] = useState<any[]>([]);
    const [descData, setRow] = useState<any>();
    const [id, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const handleOpenDialog = (id: any) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleConfirmDelete = async () => {
        if (selectedId) {
            await handleDeletePayment(selectedId);
        }
        handleCloseDialog();
    };

    const [clearFilters, setClearFilters] = useState(false);

    const pagination_ref = useRef({ page: 0, limit: 0 });
    const sumOfPages_ref = useRef<{
        [page: number]: {
            currentPageCredit: number;
            currentPageDebit: number;
            currentPageBalance: number;
        };
    }>({});

    const createSummary = (cols: any) => {
        const summaryRow = ["Total"];
        let credit = 0;
        let debit = 0;
        let advance = 0;

        if (creditDebitData) {
            let currentPage = pagination_ref.current.page;

            // Calculate the totals for the current page
            let currentPageCredit = 0;
            let currentPageDebit = 0;
            let currentPageBalance = 0;

            for (const row of creditDebitData) {
                currentPageCredit += row["credit"];
                currentPageDebit += row["debit"];
                currentPageBalance = row["balance"]; // Assuming balance is the last value
            }

            sumOfPages_ref.current = {
                ...sumOfPages_ref.current,
                [currentPage]: {
                    currentPageCredit,
                    currentPageBalance,
                    currentPageDebit,
                },
            };
        }

        console.log(cols);
        // Aggregate totals from all stored pages
        for (const col of cols) {
            const currPage = pagination_ref.current.page;
            const sums = sumOfPages_ref.current;

            switch (col) {
                case "credit":
                    {
                        const currPageCredit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce((prev, [p, { currentPageCredit }]) => {
                                return prev + currentPageCredit;
                            }, 0);

                        summaryRow.push(currencyFormatter(currPageCredit));
                    }
                    break;

                case "debit":
                    {
                        const currPageDebit = Object.entries(sums)
                            .filter(([page]) => parseInt(page) <= currPage)
                            .reduce((prev, [p, { currentPageDebit }]) => {
                                return prev + currentPageDebit;
                            }, 0);

                        summaryRow.push(currencyFormatter(currPageDebit));
                    }
                    break;

                case "balance":
                    {
                        summaryRow.push(
                            currencyFormatter(
                                sums[currPage]?.currentPageBalance
                            )
                        );
                    }
                    break;

                default:
                    summaryRow.push("");
            }
        }

        return summaryRow;
    };

    const history = useHistory();
    const location = useLocation();

    function handlePaymentDetails(row: any) {
        history.push(
            `${location.pathname}/details/${row.IdView}/${filter.client}`
        );
    }

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();

        const [data, err] = await PaymentService.updatePayment(
            id as any,
            descriptionTags as any
        );

        if (data) {
            setCount(count + 1);
            setOpenDescriptionTAg(false);
        }
    };

    const getClient = async () => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");
        console.log(id);
        const [data, err] = await allFaculty(1, 1, { baseUser: id });

        if (data.rows.length) {
            setAdditionalInfo({
                name: data?.rows[0]?.name,
                address: data?.rows[0]?.city?.name,
                mobile: data?.rows[0]?.phone,
            });
        }
    };

    async function getDescriptionTags() {
        const [data, err] = await DescriptionTagsService.getDescriptionTags(
            1,
            100
        );

        if (data.rows) {
            setRow(data.rows);
        }
    }

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            id: "",
            search: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
        });

        setCount(count + 1);
    };

    const handleChange = (event: any) => {
        setDescriptionTags(event.target.value);
    };

    const handleDelete = (chipToDelete: any) => {
        setDescriptionTags((chips) =>
            chips.filter((chip) => chip !== chipToDelete)
        );
    };

    const handleDeletePayment = async (id: any) => {
        setFeedback({ ...feedback, loading: true });

        const [data, err] = await PaymentService.deletePayment(id);
        if (data) {
            setFeedback({
                loading: false,
                hidden: false,
                severity: "success",
                message: data.message,
            });
            setCount((c) => c + 1);
        } else {
            setFeedback({
                loading: false,
                hidden: false,
                severity: "error",
                message: err || "Failed to delete the payment",
            });
        }
    };

    useEffect(() => {
        getDescriptionTags();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const id = params.get("id");

        if (Boolean(id)) {
            setFilter(
                (f) =>
                    ({
                        ...f,
                        client: id,
                        apply: filter.apply + 1,
                    } as any)
            );
        }
        getClient();
    }, [location]);

    return (
        <div>
            <Grid container spacing={1} style={{ padding: "1rem" }}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            setFilter({
                                ...filter,
                                client: opt?.baseUser?.id ?? "",
                            });

                            setAdditionalInfo({
                                name: opt?.name,
                                address: opt?.city?.name,
                                mobile: opt?.phone,
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType,
                        }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/payment/method/get-All"
                        setOutput={(bank: any) => {
                            if (!bank) return;
                            setFilter({ ...filter, bank: bank.id });
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/payment/get-status"
                        setOutput={(des: any) => {
                            if (!des) return;
                            setFilter({
                                ...filter,
                                status: des.status,
                            });
                        }}
                        labelKey="label"
                        label="Status"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        style={{ height: "80%" }}
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>

                <Grid item xs={3} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            {feedback.message && !feedback.hidden && (
                <Alert
                    severity={feedback.severity as any}
                    style={{ width: "100%" }}
                    action={
                        <IconButton
                            size="small"
                            onClick={() =>
                                setFeedback({ ...feedback, hidden: true })
                            }
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    }
                >
                    {feedback.message}
                </Alert>
            )}

            {filter.apply >= 1 ? (
                <RUDTable
                    getter={(page, limit) => {
                        pagination_ref.current = { page, limit };
                        return PaymentService.getPayments(page, limit, {
                            search: filter.search ? filter.search : null,
                            client: filter.client ? filter.client : null,
                            clientType: filter.clientType
                                ? filter.clientType
                                : null,
                            // city: filter.client ? filter.client : null,
                            bank: filter.bank ? filter.bank : null,
                            startDate: filter.startDate
                                ? filter.startDate
                                : null,
                            endDate: filter.endDate ? filter.endDate : null,
                            status: filter.status ? filter.status : null,

                            // classroom: filter.classroom ? filter.classroom : null,
                        });
                    }}
                    readables={{
                        id: "Transaction Number",
                        name: "Payment Name",
                        client: "Client Name",
                        createdAt: "Creation Date",
                        credit: "Credit",
                        debit: "Debit",
                        balance: "Balance",
                        bank: "Bank Name",
                        status: "Status",
                        description: "Description",
                        descriptionTags: "Description Tags",
                        paymentHeads: "Payment Heads",
                        showImage: "Show Image",
                        details: "Details",
                        ImageUpdate: "Image Update",
                        Delete: "Delete",
                    }}
                    rowsPreprocessor={(r) => ({
                        ...r,
                        deletionId: r.id,
                        client: r?.client?.employee?.name,
                        clientId: r.client.id,
                        IdView: r.id,
                        bank: r?.bank?.name ? (
                            <Chip label={r.bank.name} size="small" />
                        ) : (
                            <Typography
                                style={{ color: theme.palette.divider }}
                            >
                                N/A
                            </Typography>
                        ),

                        description: r.description ? (
                            r.description
                        ) : (
                            <Typography
                                style={{ color: theme.palette.divider }}
                            >
                                N/A
                            </Typography>
                        ),

                        createdAt: dateFormatter.format(new Date(r.createdAt)),

                        name: r?.name || (
                            <Typography
                                style={{ color: theme.palette.divider }}
                            >
                                N/A
                            </Typography>
                        ),
                        status:
                            r.status == "payment" &&
                            r.credit == 0 &&
                            r.debit >= 0 ? (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #7F0900, #FF1200)",
                                    }}
                                    label="Debit"
                                />
                            ) : r.status == "payment" &&
                              r.credit >= 0 &&
                              r.debit == 0 ? (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(45deg, #45BF55, #00261C)",
                                    }}
                                    label="Credit"
                                />
                            ) : r.status == "adjustment" &&
                              r.credit >= 0 &&
                              r.debit == 0 ? (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(0deg,#F2AA00,#FF7C00)",
                                    }}
                                    label="Adjustment"
                                />
                            ) : r.status == "advance" ? (
                                <Chip
                                    size="small"
                                    style={{
                                        background:
                                            "linear-gradient(0deg, #023373, #0F9BF2)",
                                    }}
                                    label="Advance"
                                />
                            ) : (
                                r.status == "refund" && (
                                    <Chip
                                        size="small"
                                        style={{
                                            background:
                                                "linear-gradient(0deg, #9C3E00, #502000)",
                                        }}
                                        label="Refund"
                                    />
                                )
                            ),
                        id: "TRA-" + r.id,
                    })}
                    ops={{
                        read: "READ PAYMENT",
                        edit: "EDIT PAYMENT",
                        delete: "DELETE PAYMENT",
                    }}
                    updatingAgents={[filter.search, filter.apply, count]}
                    editables={[]}
                    customCols={[
                        {
                            header: "descriptionTags",
                            content: (row: any) => {
                                const desc =
                                    row.descTagsComponent.length > 0 ? (
                                        <>
                                            <div
                                                onClick={(ev) => {
                                                    setId(row.IdView);
                                                    ev.stopPropagation();
                                                    setOpenDescriptionTAg(
                                                        row.IdView
                                                    );
                                                    setDescriptionTags(
                                                        row?.descTagsComponent?.map(
                                                            (item: any) =>
                                                                item?.descTags
                                                                    ?.id
                                                        )
                                                    );
                                                }}
                                            >
                                                {openDescriptionTag !=
                                                    row.IdView && (
                                                    <DescriptionTagsView
                                                        row={row}
                                                    />
                                                )}
                                            </div>

                                            {openDescriptionTag ==
                                                row.IdView && (
                                                <div
                                                    style={{
                                                        minWidth: "280px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1px",
                                                    }}
                                                >
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                    >
                                                        <InputLabel id="payment-type-label">
                                                            Payment Type
                                                        </InputLabel>
                                                        <Select
                                                            labelId="payment-type-label"
                                                            multiple // Enable multiple selection
                                                            value={
                                                                descriptionTags
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            label="Payment Type"
                                                            defaultValue={row?.descTagsComponent?.map(
                                                                (item: any) =>
                                                                    item
                                                                        ?.descTags
                                                                        ?.id
                                                            )}
                                                            renderValue={(
                                                                selected: any
                                                            ) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        flexWrap:
                                                                            "wrap",
                                                                    }}
                                                                >
                                                                    {selected.map(
                                                                        (
                                                                            value: any
                                                                        ) => (
                                                                            <Chip
                                                                                key={
                                                                                    value
                                                                                }
                                                                                label={
                                                                                    descData.find(
                                                                                        (
                                                                                            item: any
                                                                                        ) =>
                                                                                            item.id ===
                                                                                            value
                                                                                    )
                                                                                        ?.name
                                                                                }
                                                                                onDelete={() =>
                                                                                    handleDelete(
                                                                                        value
                                                                                    )
                                                                                }
                                                                                onMouseDown={(
                                                                                    event
                                                                                ) =>
                                                                                    event.stopPropagation()
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Box>
                                                            )}
                                                        >
                                                            {descData.map(
                                                                (item: any) => (
                                                                    <MenuItem
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>

                                                    <IconButton
                                                        size="small"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Check fontSize="small" />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            setOpenDescriptionTAg(
                                                                false
                                                            )
                                                        }
                                                        size="small"
                                                    >
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <Typography
                                            style={{
                                                color: theme.palette.divider,
                                            }}
                                        >
                                            N/A
                                        </Typography>
                                    );

                                return desc;
                            },
                        },

                        {
                            header: "paymentHeads",
                            content: (row) => {
                                return <PaymentHeadView row={row} />;
                            },
                        },

                        {
                            header: "showImage",
                            content: (row) => (
                                <PaymentImagePreview id={row.IdView} />
                            ),
                        },

                        {
                            header: permissions?.includes("EDIT PAYMENT")
                                ? "ImageUpdate"
                                : "",

                            content: (row) => (
                                <MultiPermissionAuthorize
                                    ops={["EDIT PAYMENT"]}
                                >
                                    <UpdateReceipt id={row.IdView} />
                                </MultiPermissionAuthorize>
                            ),
                        },

                        {
                            header: "details",
                            content: (row) => (
                                <Tooltip
                                    title="Payment Details"
                                    className={classes.tooltipSize}
                                >
                                    <IconButton
                                        onClick={() =>
                                            handlePaymentDetails(row)
                                        }
                                        size="small"
                                    >
                                        <InfoOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },

                        {
                            header: "Delete",
                            content: (row) => {
                                return (
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handleOpenDialog(row.deletionId)
                                        }
                                    >
                                        <Archive />
                                    </IconButton>
                                );
                            },
                        },
                    ]}
                    getRows={(rows) => {
                        return setCreditDebitData(rows);
                    }}
                    summaryRow={createSummary}
                    filters={
                        <Filteration
                            handleClear={handleFilterClear}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    }
                    storageKey="CreditDebit"
                    additionalPrintInfo={additionalInfo}
                />
            ) : (
                <Alert severity="info">
                    Please select a client to view leisure record.
                </Alert>
            )}

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this payment? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        style={{ color: "red" }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="primary"
                        autoFocus
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CreditDebitHistory;
