import { Box, Typography, makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext } from "react";
import { EditableTypography } from "../../EditableTypography";
import UserContext from "../../UserContext";
import { ReportHeaderProps } from "./HeaderPreferences";

type Props = {
    orgInfo: ReportHeaderProps;
    setOrgInfo?: Dispatch<SetStateAction<ReportHeaderProps>>;
};

export function ReportHeader({ orgInfo, setOrgInfo }: Props) {
    const classes = useStyles();
    const { store, settings, user } = useContext(UserContext);

    return (
        <Box textAlign="center" className={classes.wrapper}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={4}
                flexDirection="column"
            >
                <Box mb={1}>
                    {store.logo && (
                        <img
                            style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                                height: "50%", // Ensures the image uses the full height
                                width: "50%", // Ensures the image uses the full width
                            }}
                            alt="logo"
                            src={URL.createObjectURL(store.logo)}
                        />
                    )}
                </Box>

                <Box textAlign="center">
                    {orgInfo.name.selected && (
                        <Typography variant="h3" className={classes.orgName}>
                            {settings["organization-name"]
                                ? settings["organization-name"]
                                : user &&
                                  user.baseUser.type === "user" &&
                                  user.classroom !== null
                                ? user.classroom.class
                                : "Zama Classroom"}
                        </Typography>
                    )}

                    {orgInfo.address.selected && (
                        <EditableTypography
                            typoProps={{
                                variant: "h5",
                                className: classes.address,
                            }}
                            initialValue={
                                orgInfo.address.value || "Your Address"
                            }
                            onEdit={(value) => {
                                setOrgInfo &&
                                    setOrgInfo((curr) => ({
                                        ...curr,
                                        address: { ...curr.address, value },
                                    }));
                            }}
                        />
                    )}

                    {orgInfo.contactNo.selected && (
                        <EditableTypography
                            typoProps={{
                                variant: "h6",
                                className: classes.contact,
                            }}
                            initialValue={
                                orgInfo.contactNo.value || "Contact No"
                            }
                            onEdit={(value) => {
                                setOrgInfo &&
                                    setOrgInfo((curr) => ({
                                        ...curr,
                                        contactNo: { ...curr.contactNo, value },
                                    }));
                            }}
                        />
                    )}

                    {orgInfo.email.selected && (
                        <Typography variant="h6" className={classes.email}>
                            {user?.baseUser.email}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(1, 2),
    },
    orgName: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    address: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
        color: "#555",
        marginBottom: theme.spacing(1),
    },
    contact: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 400,
        color: "#777",
        marginBottom: theme.spacing(1),
    },
    email: {
        fontFamily: "'Roboto Mono', monospace",
        fontWeight: 300,
        color: "#333",
    },
}));
